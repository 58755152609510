@import url('https://fonts.googleapis.com/css?family=Montserrat:200,400,500,600,700,800');
body {
  color: #000036;
  font-weight: 600
}
.label-block {
  display: block;
}
.float-block {
  margin: 0 1.81em 0 2em;
  margin-left: 20px;
}
.container-block {
	border: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	break-before: always;
	margin: 0 0 3em 0;
}
input {
  outline: none !important;
}
input:focus {
  border: 1.5px solid #000036 !important;
  outline: none !important;
}
:focus {
  outline-color: transparent;
  outline-style: none;
}
ul, li {
  list-style: none;
}
input {
  width: 100%
}
/* .fa-icons{
  font-family: 'Font Awesome 5 Free';
} */
.parent-category {
  width: 100%;
  margin: 5px 0;
}
.btn {
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
button.close {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-scrollbar::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 25px;
  /* width: 5px; */
  background-color: #808996;
}
.custom-scrollbar::-webkit-scrollbar{
  height: 5px;
  width: 5px;
  background-color: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb{
  border-radius: 25px;
  /* width: 5px; */
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #3c4d69;
}
.btn {
  border-radius: 1rem;
}
.btn-dark {
  background-color: #000063 !important;
  border-color: #343a40 !important;
}
.btn-dark:hover {
  background-color: rgb(10, 10, 117) !important;
  border-color: #343a40 !important;
}
.btn-light {
  background-color: #7163b8 !important;
  border-color: #343a40 !important;
}
.btn-light:hover {
  background-color: #665aa3 !important;
  border-color: #343a40 !important;
}
.badge-light{
  background-color: #83d3f0;
  color: white;
}
.form-control {
  background-color: rgba(255,255,255,0.8)
}
.form-control:focus {
  border: 1.5px solid #7163b8;
}
.form-control:focus {
  border: 1.5px solid #7163b8;
}

.form-single[disabled], .form-single[readonly], fieldset[disabled] .form-single{
  background-color: transparent;
  color: rgb(235, 235, 235);
  cursor: not-allowed;
  border: 1px solid transparent;
}
.form-single {
  font-weight: bold;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.57)
}
/* .form-single:focus {
  border: 1.5px solid green;
} */

@media screen and (max-width: 768px) {
  .nav-tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}
select{text-transform: capitalize;}
.text-primary,
a.text-primary:focus, a.text-primary:hover {
  color: #bbbbff !important; }
.sidebar:after,
.off-canvas-sidebar:after {
  background: #ffffff !important;
  z-index: 3; }
.sidebar .sidebar-wrapper, .sidebar, .off-canvas-sidebar,
.off-canvas-sidebar .sidebar-wrapper.main-panel {
  width: 200px;
}
.main-panel {
  background: #f3f5fa !important;
  /* background: #141424 !important; */
  width: calc(100% - 200px);
}
@media screen and (max-width: 992px) {
  .main-panel {
    width: 100%;
  }
  .nav-open .main-panel {
    -webkit-transform: translate3d(200px, 0, 0);
    -ms-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
  .sidebar, .bootstrap-navbar {
    width: 200px;
  }
}
.panel-header {
  background: #000080 !important;
  position: sticky;
  top: 0;
}
/* FOR TABS */
.card-tab {
  /* border-radius: 0 0 0.8rem 0.8rem; */
  box-shadow: none;
  padding: 15px 15px;
  margin-bottom: 0;
}
.card {
  background: #fff;
  /* box-shadow: 9px 9px 15px 0 #191938; */
  border-radius: 1rem;
}
/* .kanban-card {
  border-top: 3px solid;
} */
/* SIDEBAR ACTIVE NAV */
.sidebar .nav li.active > a:not([data-toggle="collapse"]), .off-canvas-sidebar .nav li.active > a:not([data-toggle="collapse"]) {
  background-color: #000080;
  color:#ffffff;
  padding: 10px 15px;
  margin: 0;
  /* border-radius: 0; */
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.card-body li.active > a:not([data-toggle="collapse"]), .off-canvas-sidebar .nav li.active > a:not([data-toggle="collapse"])  {
  background-color: #9675c1 !important;
  /* border-radius: 0 25px 25px 0; */
  padding: 5px 15px !important;
}
.sidebar .nav li.active i {
  color: #ffffff;
}
/* SIDEBAR ALL NAV */
.sidebar .nav li:first-child > a, .off-canvas-sidebar .nav li:first-child > a {
  margin: 0;
}
.sidebar .nav li:hover:not(.active) > a i, .sidebar .nav li:focus:not(.active) > a i, .off-canvas-sidebar .nav li:hover:not(.active) > a i, .off-canvas-sidebar .nav li:focus:not(.active) > a i {
  color: black;
}
.sidebar .nav li > a, .sidebar .nav li > a:not([href]):not([tabindex]):focus, .sidebar .nav li > a:not([href]):not([tabindex]):hover, .off-canvas-sidebar .nav li > a, .off-canvas-sidebar .nav li > a:not([href]):not([tabindex]):focus, .off-canvas-sidebar .nav li > a:not([href]):not([tabindex]):hover {
  /* text-align: center; */
  margin: 0;
  padding: 10px 15px;
  border-radius: 0;
  color: black;
}
.sidebar .logo, .off-canvas-sidebar .logo {
  height: 135px;
  background: #0c0080 !important;
  border-radius: 0 0 0 5rem;
  padding: 1.8rem 1rem 0.5rem
}
.sidebar .logo:after, .off-canvas-sidebar .logo:after {
  background-color: rgba(255, 255, 255, 0);
}
.sidebar .nav i, .off-canvas-sidebar .nav i {
  margin-right: 5px;
  color: black;
}
.navbar .navbar-brand {
  font-weight: bold;
}
.provideList {
  width: 100%;
  /* padding: 10px 15px; */
  margin-bottom: 8px;
  box-shadow: none;
  /* -webkit-box-shadow: 0 10px 6px -6px #777;
       -moz-box-shadow: 0 10px 6px -6px #777;
            box-shadow: 0 10px 6px -6px #777; */
  background: #0b0050;
  border-radius: 15px;
  position: relative;
  color: white
  /* border-bottom: 1px solid #595959; */
}
.unassigned {
  background: rgba(87, 87, 87,0.35);
}
.threeTabs {
  width: 33.3333%;
}
/* ============= */
/* MODAL CHANGES */
/* ============= */
div.modal-sections > div.modal-content{
  background-color: transparent;
  border: none;
}
.modal-dialog {
  max-width: 75%;
  color: #000040
}
.modal-wrapper {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}
.modal-title{
  font-weight: bold
}
.add-modal {
  max-width: 65%;
}
.smaller-col {
  width: 5%;
  padding: 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column
}
.smaller-col>* {
  padding: 10px 0;
}
@media screen and (max-width: 768px) {
  .smaller-col {
    width: 100%;
    padding: 0 15px 10px;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }
  .smaller-col>img {
    width: 8% !important;
  }
  .modal-dialog, .add-modal {
    max-width: 90%;
  }
  .reverse-mobile{
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 576px) {
  .smaller-col>img {
    width: 15% !important;
  }
  .modal-dialog, .add-modal {
    max-width: 100%;
  }
  .hide-mobile {
    display: none;
  }
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  font-weight: bold !important;
  border-bottom: 3px solid #0b0043;
  border-color: transparent transparent #0b0043;
}
select {
  /* Removes the default <select> styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Positions background arrow image */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: 95% center;
}
.tab-content {
  padding: 15px;
  background: #ffffff;
  border-radius: 0 0 0.25rem 0.25rem;
  color: #000040;
}
.shinyButton {
  background: #000080;
  -webkit-box-shadow:
    3px 3px 6px rgba(0, 0, 0, .20),
    0px 0px 3px rgba(0, 0, 0, .40),
    inset 0px 25px 25px #0000eb,
    inset 0px 20px 2px rgba(0, 0, 235, 0.5),
    inset 0px 5px 12px #000080;
}
.shinyButton:hover {
  background: #000080 !important;
  -webkit-box-shadow:
    2px 2px 4px rgba(0, 0, 0, .2),
    0px 0px 3px rgba(0, 0, 0, .1),
    inset 0px 10px 12px #0000eb,
    inset 0px 20px 2px rgba(4,4,176, .7),
    inset 0px 25px 25px #000080 !important;
}

.shinyButton2{
  padding: 5px 30px;
  /* border: 1px solid #000080; */
  border-radius: 25px;
  -webkit-box-shadow: inset 1px 6px 12px #0000a7, inset -1px -10px 5px #000059, 1px 2px 1px black;
  -moz-box-shadow: inset 1px 6px 12px #0000a7, inset -1px -10px 5px #000059, 1px 2px 1px black;
  box-shadow: inset 1px 6px 12px #0000a7, inset -1px -10px 5px #000059, 1px 2px 1px black;
  background-color: #000080;
  color: white;
  text-shadow: 1px 1px 1px black;
}
.shinyButton2:hover {
  background-color: #000080;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: #bbbbff !important;
  font-weight: bold !important;
  border-bottom: 3px solid #bbbbff;
  border-color: transparent transparent #bbbbff;
}
 /* Fikry added this */
.loader {
  border: 8px solid #d1d1d1;
  border-radius: 50%;
  border-top: 8px solid #00005e;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.btnloader {
  border: 3px solid #d1d1d1;
  border-radius: 50%;
  border-top: 3px solid #00005e;
  width: 15px;
  height: 15px;
  margin: 0 auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.loading-screen {
  background: url('../img/serv-white.png') no-repeat center;
  margin: 0 auto;
  width: 50%;
  height: 100vh;
  -webkit-animation: zoom 1s ease-in infinite forwards; /* Safari */
  animation: zoom 1s ease-in infinite forwards;
}
.buttonloader {
  border: 3px solid #7c7c7c;
  border-radius: 50%;
  border-top: 3px solid #00005e;
  width: 20px;
  height: 20px;
  /* margin: 0 auto; */
  display: inline-block;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}
.blinking {
  width:15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  -webkit-animation: blinkanimate 1s linear infinite; /* Safari */
  animation: blinkanimate 1s linear infinite;
}
.red-dot {
  width:15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 50%;
  text-align: center;
  line-height: normal;
  transform: translateY(-50%);
  background-color: #dc3545;
}

@-webkit-keyframes zoom {
  0%,100% {
    -webkit-transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.5);
  }
}

@keyframes zoom {
  0%,100% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.5);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Fikry added this */
.text-change {
  text-align: left;
}

.approve-modal {
  max-width: 35%;
}

@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
  .approve-modal {
    max-width: 75%;
  }
}
@media screen and (max-width: 576px) {
  .text-change {
    text-align: center;
    margin: 10px 0 15px;
  }
  .approve-modal {
    max-width: 100%;
  }
}

.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #494949;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

@keyframes blinkanimate {
  0% {  background: #dc3545; }
  50% { background: #dc3545; }
  60% { background: transparent; }
  70% { background:transparent; }
  100% { background: #dc3545; }
}

.rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}
.rating>span {
  background: #505050;
  border: 1px solid #3a3a3a;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 5px;
}

/* RESPONSIVE ADJUSTMENTS */
.border-left-resp {
  border-left: 1px solid #333333;
}
.position-absolute-resp {
  margin:0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.text-right-resp {
  text-align: right;
}

@media screen and (max-width: 576px) {
  .border-left-resp {
    border-left: none;
  }

  .position-absolute-resp {
    position: relative;
  }

  .text-right-resp {
    text-align: left;
    margin-top: 5px
  }
}

.max-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.ul-bug {
  list-style: none;
  display: table;
}

.li-bug {
  display: table-row;
}

.b-bug {
  display: table-cell;
  padding-right: 1em;
}