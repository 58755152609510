.right-column {
  padding: 15px;
  background: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-flow: column;
}
.left-column {
  display: none;
}

.login-logo {
  width: 50%;
  margin: 0 auto;
}

.art {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 480px) {
  /* phones */
}
@media only screen and (min-width: 768px) {
  /* desktop */
  .left-column {
    display: block;
  }
  .login-logo {
    width: 55%;
  }
}
@media only screen and (min-width: 900px) {
  /* desktop */
}
.btn-login {
  background-color: #000080 !important;
  border-radius: 10px !important;
  color: #fff !important;
}
.btn-login:hover {
  background-color: #0101a0;
  border-radius: 10px !important;
}
.login-form {
  width: 80%;
  margin: 0 auto;
}
.login-form .group {
  margin-bottom: 15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button {
  width: 100%;
  display: block;
}
.login-form .group .input,
.login-form .group .button {
  border: 3px solid #000080;
  padding: 15px 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
}
.input:focus {
  border: 3px solid #000080 !important;
  padding: 15px 20px !important;
}
.login-form .group input[data-type="password"] {
  text-security: circle;
  -webkit-text-security: circle;
}
.login-form .group .label {
  color: #aaa;
  font-size: 12px;
}
.login-form .group .button {
  background: #000080;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.login-form .group label .icon {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after {
  content: "";
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  transition: all 0.2s ease-in-out 0s;
}
.login-form .group label .icon:before {
  left: 3px;
  width: 5px;
  bottom: 6px;
  transform: scale(0) rotate(0);
}
.login-form .group label .icon:after {
  top: 6px;
  right: 0;
  transform: scale(0) rotate(0);
}
.login-form .group .check:checked + label {
  color: #fff;
}
.login-form .group .check:checked + label .icon {
  background: #000080;
}
.login-form .group .check:checked + label .icon:before {
  transform: scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after {
  transform: scale(1) rotate(-45deg);
}
